.page-info-center-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

.page-info-image {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
}

.page-info-image-content {
  width: 240px !important;
  height: 240px;
}
