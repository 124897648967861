@import '../../node_modules/@swisscom/sdx/stylesheets/sdx/variables/_colors.scss';

html,
body,
#root {
  height: 100%;
}

body {
  background: $color-horizon-tint-2 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: none !important;
}

#main {
  position: relative;
  min-height: 100%;
}

main {
  padding-bottom: 80px;
  z-index: 1;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.accessibility-mode-on *:focus,
.accessibility-mode-on [type='radio']:focus ~ span,
.accessibility-mode-on [type='checkbox']:focus ~ span {
  outline: 2px solid $color-int-blue--active !important;
  box-shadow: none !important;
}

.accessibility-mode-on .icon-button:focus,
.accessibility-mode-on .react-select__input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accessibility-mode-on .react-select__control:focus-within {
  border-color: $color-int-blue--active !important;
}

.accessibility-mode-on .flyout-opener:focus-within .sc-gray {
  color: $color-int-blue--active;
}

*:not(input) {
  outline: none !important;
  box-sizing: border-box;
}

.app-notification {
  z-index: 5 !important;
}

@media (max-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
  }

  .footer .logo {
    margin-top: 15px !important;
    margin-bottom: 12px;
    width: auto !important;
  }
}

@media (max-width: 480px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .container {
    width: 800px !important;
  }
}

.notification-header .notification__content .notification__body {
  white-space: normal !important;
}

.text-align-center {
  text-align: center;
}

.align-center {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

h1,
h2,
h3,
h4,
h5 {
  color: $color-sc-navy;
}

.loading-button {
  margin: 10px auto 0 auto;
}

.loading-page {
  margin: 40px auto 0 auto;
  display: table;
}
